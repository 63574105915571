footer {
  text-align: center;
  padding: 3em 2em 2em 2em;
  margin-top: 2em;
}

@media (min-width: 768px) {
  footer {
    padding: 4em 6em 2em 6em;
    margin-top: 6em;
  }
}

footer .logo {
  display: inline-block;
  width: auto;
  max-width: 100%;
  max-height: 10em;
}

#starm-logo {
  max-width: 100%;
  max-height: 7em;
  margin-bottom: 3em;
  margin-top: 0;
}

@media (min-width:992px) {
  #starm-logo {
    max-height: 9em;
    margin-top: 1em;
    margin-bottom: 0;
  }
}

#ideal-logo {
  margin-top: -0.7em;
}

#uach-logo {
  margin-top: 0.4em;
}

#do-logo {
  margin-top: 1.25em;
  max-height: 7em;
  max-width: 70%;
  ;
}

@media (max-width: 600px) {
  #do-logo {
    max-width: 90%;
  }
}

#parque-logo {
  margin-top: 0.5em;
  height: auto;
}

#austral-logo {
  margin-top: 0em;
  max-height: 9em;
  max-width: 80%;
}