.about-logo {
  max-height: 200px;
  max-width: 380px;
  padding: 1rem;
}

@media (min-width: 768px) {
  .about-logo {
    max-height: 200px;
    max-width: 300px;
  }
}
