.filters-section {
  background-color: #fff;
}
.minimap {
  flex: 1 0 600px;
}
.minimap,
.graph {
  z-index: 0;
  position: relative;
  overflow-x: visible;
  display: block;
}
.minimap-legend {
  border: 1px solid #999;
  border-radius: 0.2rem;
  width: 11rem;
  background-color: #fff;
  text-align: center;
}
.minimap-legend > div {
  margin-top: -2rem;
  max-height: 20rem;
}
.minimap-legend h4 {
  margin: 0;
}
.minimap-legend p {
  margin: -1rem 0;
  font-size: 1rem;
}
#colorcuts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  margin-top: 0.2rem;
  max-height: 13.4rem;
}
.cut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
  margin-right: 1.4rem;
  max-height: 1.4rem;
}
.cut > p {
  display: inline-block;
  margin: 0.1rem 0;
  max-height: 100%;
}
.color {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
}
#colormap-btn {
  z-index: 1000;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}
#colormap:hover {
  cursor: pointer;
}
[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 100%;
  --amplify-components-authenticator-container-height-max: 100%;
}
[data-amplify-authenticator] .amplify-flex,
[data-amplify-authenticator] .amplify-tabs,
[data-amplify-authenticator] .amplify-tabs-item,
[data-amplify-authenticator] .amplify-input,
[data-amplify-authenticator] .amplify-label,
[data-amplify-authenticator] .amplify-alert,
[data-amplify-authenticator] .amplify-alert__body,
.amplify-heading,
.amplify-text,
.amplify-button {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.amplify-heading {
  font-size: 1.5rem;
}
[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: 0px 2px 4px hsla(210, 50%, 10%, 0.15) !important;
  border: none !important;
}
.space-before {
  display: inline-block;
  margin-left: 0.5rem !important;
}

/*
@media screen and (max-width: 420px) {
    .graph {
        position: absolute;
        z-index: 5!important;
        margin: 0 1rem;
        min-height: 20rem;
    }
}
*/

.graph {
  flex: 1 0 600px;
}
.graph.is-large {
  flex: 1 0 100%;
}

.graph-container {
  background-color: #fff;
  margin: 0 auto;
  box-sizing: border-box !important;
  height: 100%;
}

.highcharts-container {
  height: 100%;
  margin: 0 auto;
}
.graph-container > .highcharts-container > .highcharts-root {
  display: block;
  margin: 0 auto !important;
  min-height: 18rem !important;
  height: 100% !important;
}
@media screen and (orientation: landscape) {
  .graph-container {
    max-width: 100vw;
  }
  .graph-container > .highcharts-container > .highcharts-root {
    max-width: 100vw;
  }
}

/** || BOOTSTRAP **/
.accordion-button:focus {
  border-color: #1f9097;
}
.accordion-button:not(.collapsed) {
  color: #1f9097;
  background-color: rgba(28, 132, 138, 0.2);
}

/** || SENSORS DETAILS **/
.sensor-title {
  position: absolute;
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.4);
}

/** || 404 **/
#title-404 {
  font-size: 6rem;
}
