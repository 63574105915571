.loading-icon {
    /*  Safari & Chrome Compatibility   */
    -webkit-animation-name: infinite-spin-animation;
    -webkit-animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    /*  Firefox Compatibility   */
    -moz-animation-name: infinite-spin-animation;
    -moz-animation-duration: 1.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    /*  Opera Compatibility   */
    -o-animation-name: infinite-spin-animation;
    -o-animation-duration: 1.4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: ease-in-out;
    /*  IE & Microsoft Edge Compatibility   */
    -ms-animation-name: infinite-spin-animation;
    -ms-animation-duration: 1.4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: ease-in-out;
    /*  Common Fallback */
    z-index: 1000;
    margin: 4rem 0;
    animation-name: infinite-spin-animation;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
/*  Safari & Chrome Compatibility   */
@-webkit-keyframes infinite-spin-animation {
    100% {-webkit-transform: rotate(360deg);}
}
/*  Firefox Compatibility   */
@-moz-keyframes infinite-spin-animation {
    100% {-moz-rotate: 360deg;}
}
/*  Opera Compatibility   */
@-o-keyframes infinite-spin-animation {
    100% {-o-transform: rotate(360deg);}
}
/*  IE & Microsoft Edge Compatibility   */
@-ms-keyframes infinite-spin-animation {
    100% {-ms-transform: rotate(360deg);}
}
/* Common Fallback */
@keyframes infinite-spin-animation {
    100% {transform: rotate(360deg);}
}

.rotate-device {
    z-index: 1200;
    position: fixed;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100vw;
    height: 100%;
    padding: auto;
    background-color: rgba(51, 51, 51, .6);
    color: #FFFFFF;
}

@media screen and (max-width: 420px) and (orientation: portrait) {
    .rotate-device {
        visibility: visible;
    }
}

.rotate-icon {
    /*  Safari & Chrome Compatibility   */
    -webkit-animation-name: simple-spin-animation;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    /*  Firefox Compatibility   */
    -moz-animation-name: simple-spin-animation;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: ease-in-out;
    /*  Opera Compatibility   */
    -o-animation-name: simple-spin-animation;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: 1;
    -o-animation-timing-function: ease-in-out;
    /*  IE & Microsoft Edge Compatibility   */
    -ms-animation-name: simple-spin-animation;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: ease-in-out;
    /* Common Fallback */
    animation-name: simple-spin-animation;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

/*  Safari & Chrome Compatibility   */
@-webkit-keyframes simple-spin-animation {
    100% {-webkit-transform: rotate(360deg);}
}

/*  Firefox Compatibility   */
@-moz-keyframes simple-spin-animation {
    100% {-moz-rotate: 360deg;}
}

/*  Opera Compatibility   */
@-o-keyframes simple-spin-animation {
    100% {-o-transform: rotate(360deg);}
}

/*  IE & Microsoft Edge Compatibility   */
@-ms-keyframes simple-spin-animation {
    100% {-ms-transform: rotate(360deg);}
}

/*  Common Fallback */
@keyframes simple-spin-animation {
    100% {transform: rotate(360deg);}
}

.custom-placeholder {
    margin: 10vh auto!important;
}

#root {
  white-space: pre-line;
}
