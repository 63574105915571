.accordion-button:focus {
  border-color: #1f9097;
}
.accordion-button:not(.collapsed) {
  color: #1f9097;
  background-color: rgba(28, 132, 138, 0.2);
}

.accordion-body {
  max-height: 300px;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .accordion-body {
    max-height: unset;
  }
}
