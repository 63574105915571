nav.navbar {
  background-color: #083253;
}

nav.navbar .logo {
  height: 60px;
  width: auto;
}

nav.navbar .nav-title {
  font-size: 1rem;
  font-weight: bold;
}

nav.navbar .nav-subtitle {
  font-size: 0.75rem;
}

@media (min-width: 576px) {
  nav.navbar .nav-title {
    font-size: 1.2rem;
  }

  nav.navbar .nav-subtitle {
    font-size: 1rem;
  }
}

main {
  width: 100%;
  flex: 1 0 auto;
}

main.fix-height {
  min-height: calc(100% - 103px);
  height: calc(100% - 103px);
}

.nav-bar {
  min-height: 100px;
}

.card-img {
  aspect-ratio: 36/9;
  object-fit: cover;
  min-height: 220px;
}

.bg-accent {
  background-color: #24a0a7;
}

.text-accent {
  color: #24a0a7;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-small {
  font-size: 0.75rem;
}

body {
  background-color: #f8f8f8;
}

@media (max-width: 375px) {
  .hide-on-xxs {
    display: none;
  }
}

#accordionSensors .list-group-item {
  padding-left: 3rem;
}


.sensor-subtitle-vars{
  margin: 1.5em 0 0.5em -0.75em;
  color: #24a0a7;
}

h5.card-title{
  font-weight: bold;
}