
#map {
    width: 100%;
    height: 100%;
    box-shadow: 0px 2px 2px hsl(210deg 50% 10% / 15%) !important;
}
#legends {
    z-index: 1000;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
}
#legend-btn {
    z-index: 1000;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
}
#close-legends {
    position: absolute;
    top: .4rem;
    right: .1rem;
}

/* MAP ICONS || */
.map-icon {
    max-width: 4rem;
    height: auto;
}
.default-icon {
    color: gold;
}
.ferrybox-icon {
    max-width: .4rem;
    color: #FFD400;
}
.cruiser-icon {
    max-width: 1rem;
    color: blue;
}

/* OVERRIDE OF EXTERNAL LIBRARIES STYLES || */
.leaflet-div-icon {
    border-color: rgba(1,1,1,0);
    background-color: rgba(1,1,1,0);
}
.leaflet-container {
    font-size: 1rem !important;
}
.leaflet-control-layers label {
    font-size:0.9rem !important;
}
.leaflet-layerstree-header input {
    margin-right:5px;
}
